import { Injectable, Inject, forwardRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Transform, Leaderboard, LeaderboardResponse } from 'ec-leaderboard/dist/leaderboard';

import { BaseService, ToastService, GeneralService } from '../../base/services';
import { Model } from '../../base/models/model';

import { LeaderboardApi } from '../api';
import {take, tap} from 'rxjs/operators';
import { of } from 'rxjs';


@Injectable()
export class LeaderboardService extends BaseService {
    private readonly leaderboardModel = Model.create<Leaderboard | undefined>(undefined);

    readonly leaderboard$ = this.leaderboardModel.data$;

    constructor(
        @Inject(forwardRef(() => HttpClient))
        http: HttpClient,
        toastService: ToastService,
        private generalService: GeneralService
    ) {
        super(http, toastService);
        this.getLeaderboard();
    }

    getLeaderboard() {
        if (!this.generalService.currentTournamentData) {
            return of(null);
        }

        return this.get<LeaderboardResponse>(LeaderboardApi.getLeaderboard(this.generalService.currentTournamentData.code))
            .pipe(
                take(1),
                tap(({board}) => {
                    const value = Transform.toTeamFormat(board);
                    this.leaderboardModel.set(value);
                })
            );
    }
}
