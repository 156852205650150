import { Component, Input } from '@angular/core';
import { TeamPlayer } from 'ec-leaderboard/dist/leaderboard/lib/types';

import { ModalService } from '../../../../base/services';

@Component({
  selector: 'app-update-score',
  templateUrl: './update-score-modal.component.html',
  styleUrls: ['./update-score-modal.component.scss'],
})
export class UpdateScoreModalComponent {
  @Input() player: TeamPlayer;
  @Input() currentHole: any;
  @Input() enteredScore = '';

  scoreOptions: Array<number | string> = [1, 2, 3, 4, 5, 6, 7, 8, 9, '', 0, 'C'];

  constructor(private modalService: ModalService) { }

  adjust(score: number | string) {
    this.enteredScore = score !== 'C' ? this.enteredScore + (+score) : this.enteredScore.slice(0, -1);
  }

  save() {
    const state = {player: this.player, score: +this.enteredScore};
    this.modalService.close(state);
  }

  close() {
    this.modalService.close({});
  }
}
