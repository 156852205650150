export class GeneralApi {
    //#region Tournament

    public static getTeamScores = '/team/scores';

    public static updatePlayerScores = (hole: number, uuid: string, score: number) => `/hole/${hole}/player/${uuid}/score/${score}`;

    //#endregion

    public static getBannerUrl = (tournamentCode: string) => `/tournament/${tournamentCode}/banner`;
}
