import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable()
export class ToastService {
    constructor(private toastController: ToastController) { }

    success(message: string, color = 'success', duration = 2000) {
        this.presentToast(message, color, duration);
    }

    error(message: string, color = 'danger', duration = 2000) {
        this.presentToast(message, color, duration);
    }

    show(message: string, color = 'secondary') {
        this.presentToast(message, color);
    }

    private async presentToast(message: string, color = 'light', duration = 2000) {
        const toast = await this.toastController.create({
            message,
            color,
            duration
        });
        toast.present();
    }
}
