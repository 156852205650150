import { Component, Input } from '@angular/core';
import { TeamPlayer } from 'ec-leaderboard/dist/leaderboard';

@Component({
  templateUrl: 'score-table-modal.component.html',
  styleUrls: ['./score-table-modal.component.scss'],
})
export class ScoreTableModalComponent {
  @Input() player: TeamPlayer;

  sum = (player: TeamPlayer) => this.partialSum(player, 'front') + this.partialSum(player, 'back');

  partialSum(player: TeamPlayer, part: 'front' | 'back') {
    const middle: number = player.holes.length / 2;
    const sliceParams = part === 'front' ? [0, middle] : [middle, middle * 2];
    return player.holes.slice(...sliceParams).reduce((ac: number, val) => ac + val.score, 0);
  }
}
