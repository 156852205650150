import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent,
    HttpHeaders,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { EC_TOKEN, REQ_TIMEOUT } from '../constants';
import { catchError, map } from 'rxjs/operators';
import { LoadingController } from '@ionic/angular';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    loader: any;
    loaderDisplayed = false;

    constructor(private loadingController: LoadingController) {}

    async showLoader(url: string) {
        const messagesMap = {
            authenticate: 'Signing in',
            'team/detail': 'Retrieving team data',
            'tournament/detail': 'Retrieving tournament data',
            'team/scores': 'Retrieving team scores',
            leaderboard: 'Retrieving leaderboard data',
            banner: 'Loading additional information'
        };
        let message = 'Updating data';
        Object.keys(messagesMap).forEach((m) => message = url.includes(m) ? messagesMap[m] : message);
        await this.hideLoader();
        this.loaderDisplayed = true;
        this.loader = await this.loadingController.create({message, duration: REQ_TIMEOUT});
        await this.loader.present();
    }

    async hideLoader() {
        if (this.loader && this.loaderDisplayed) {
            this.loaderDisplayed = false;
            await this.loader.dismiss();
        }
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        if (localStorage.getItem(EC_TOKEN) !== null) {
            const token = localStorage.getItem(EC_TOKEN);
            headers = headers.set('Authorization', 'Bearer ' + token);
        }
        request = request.clone({ headers });
        this.showLoader(request.url);
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.hideLoader();
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                this.hideLoader();
                return throwError(error);
            }));
    }
}
