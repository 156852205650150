import { Component } from '@angular/core';
import { InfoService, ModalService } from '../../services';

@Component({
    selector: 'app-menu-modal',
    templateUrl: './menu-modal.component.html',
    styleUrls: ['./menu-modal.component.scss'],
})
export class MenuModalComponent {
    showSupport = false;

    constructor(private modalService: ModalService, public infoService: InfoService) { }

    close() {
        this.modalService.close();
    }

    facebook() {
        open('https://www.facebook.com/EventCaddy/', '_self');
    }

    instagram() {
        open('https://www.instagram.com/eventcaddy/?hl=en', '_self');
    }

    support() {
        this.showSupport = !this.showSupport;
    }
}
