import {Component} from '@angular/core';
import {BehaviorSubject, ReplaySubject} from 'rxjs';

import { Leaderboard, HoleInfo } from 'ec-leaderboard/dist/leaderboard';

import { InfoService, ModalService } from '../../../base/services';

import { LeaderboardService } from '../../services/leaderboard.service';
import { LeaderboardModalComponent } from '../leaderboard-modal/leaderboard-modal.component';
import { MenuModalComponent } from '../../../base/components';

@Component({
    selector: 'app-leaderboard',
    templateUrl: './leaderboard.page.html',
    styleUrls: ['./leaderboard.page.scss'],
})
export class LeaderboardPage {
    viewEnterSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    tabs = {team: 'Team', leaderboard: 'Leaderboard'};
    activeTab: 'team' | 'leaderboard' = 'team';
    leaderboard$: BehaviorSubject<Leaderboard> = new BehaviorSubject<Leaderboard>(null);
    holesInfo: HoleInfo[];
    bannerUrl = '';

    constructor(
        private modalService: ModalService,
        private infoService: InfoService,
        public leaderboardService: LeaderboardService
    ) {}

    ionViewWillEnter() {
        this.bannerUrl = this.infoService.restoreBanner();
        this.tabs.team = this.infoService.restoreTeamData().name;
        this.selTab('team');
        this.viewEnterSubject.next(true);
    }

    async scoreClickHandler($event) {
        const props = {
            modalData: $event,
            holesInfo: this.holesInfo
        };
        await this.modalService.show(LeaderboardModalComponent, 'leaderboard-modal', props);
    }

    selTab(tab: 'team' | 'leaderboard') {
        this.activeTab = tab;
        if (this.activeTab === 'leaderboard') {
            this.getLeaderboardData();
        }
    }

    menu() {
        this.modalService.show(MenuModalComponent, 'full-screen-modal', {modalData: ''});
    }

    private getLeaderboardData() {
        this.leaderboardService.getLeaderboard()
            .subscribe(
            () => setTimeout(() => this.holesInfo = this.infoService.restoreHolesData())
            );
    }
}
