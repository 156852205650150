import { Injectable, Inject, forwardRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { TeamScores, Team, TeamResponse, BaseResponse } from 'ec-leaderboard/dist/leaderboard/lib/types';

import { BaseService, ToastService, InfoService, GeneralService } from '.';
import { Model } from '../models/model';

import { GeneralApi } from '../api';

@Injectable()
export class TournamentBaseService extends BaseService {
    private readonly teamScoresModel = Model.create<TeamScores | undefined>(undefined);
    private teamData: Team;

    readonly teamScores$ = this.teamScoresModel.data$;

    constructor(
        @Inject(forwardRef(() => HttpClient))
        http: HttpClient,
        toastService: ToastService,
        private infoService: InfoService,
        private generalService: GeneralService
    ) {
        super(http, toastService);
        this.teamData = this.infoService.restoreTeamData();
        this.initTeamScores();
    }

    get currentTeamData() {
        this.teamData = this.infoService.restoreTeamData();
        return this.teamData;
    }

    private initTeamScores() {
        // building initial placeholder
        const teamScores: TeamScores = {
            tournamentName: this.getTournamentName,
            teamName: this.teamData.name || '',
            players: [{
                first_name: '',
                last_name: '',
                holes: [{
                    hole: '1',
                    score: null
                }]
            }]
        };
        this.teamScoresModel.set(teamScores);
        this.getTeamScores();
    }

    get getTournamentName() {
        return this.generalService.currentTournamentData && this.generalService.currentTournamentData.name || '';
    }

    getTeamScores() {
        return this.get<TeamResponse>(GeneralApi.getTeamScores)
            .pipe(
                tap(({team}) => {
                    const teamScores: TeamScores = {
                        tournamentName: this.getTournamentName,
                        teamName: this.teamData.name || '',
                        players: team.players && team.players.sort((a, b) => {
                            if (a.first_name + a.last_name < b.first_name + a.last_name) {
                                return -1;
                            }
                            if (a.first_name + a.last_name > b.first_name + a.last_name) {
                                return 1;
                            }
                            return 0;
                        })
                    };
                    this.teamScoresModel.set(teamScores);
                })
            );
    }

    updatePlayerScores(hole: number, uuid: string, score: number) {
        this.put<any, BaseResponse>(GeneralApi.updatePlayerScores(hole, uuid, score), null, true).subscribe();
    }
}
