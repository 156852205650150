import { Inject, forwardRef } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError, of, Subject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { BaseResponse } from 'ec-leaderboard/dist/leaderboard/lib/types';
import { API_BASE_URL } from '../constants';
import { ToastService } from './toast.service';
import { BannerResponse } from '../models/base-interface';

export abstract class BaseService {
    private busySubject = new Subject<boolean>();

    readonly busy$ = this.busySubject;

    constructor(
        @Inject(forwardRef(() => HttpClient))
        protected http: HttpClient,
        protected toastService: ToastService
    ) { }

    protected get<T extends BaseResponse>(url: string, isShowErrorMessage = true): Observable<T | any> {
        this.busySubject.next(true);
        return this.http
            .get<T>(`${API_BASE_URL}${url}`)
            .pipe(
                switchMap(this.handleResult(false, isShowErrorMessage)),
                catchError(this.handleError<T>(`GET: ${url}`, isShowErrorMessage))
            );
    }

    protected post<T extends BaseResponse>(data: any, url: string, isShowSuccessMessage = false, errorMessage?: (error) => string) {
        this.busySubject.next(true);
        return this.http
            .post<T>(`${API_BASE_URL}${url}`, JSON.stringify(data))
            .pipe(
                switchMap(this.handleResult(isShowSuccessMessage)),
                catchError(this.handleError(`POST: ${url}`))
            );
    }

    protected put<S, T extends BaseResponse>(url: string, dto: S, isShowSuccessMessage = false) {
        this.busySubject.next(true);
        return this.http
            .put<T>(`${API_BASE_URL}${url}`, dto)
            .pipe(
                switchMap(this.handleResult(isShowSuccessMessage)),
                catchError(this.handleError<T>(`PUT: ${url}`)),
            );
    }

    private handleResult(isShowSuccessMessage = false, isShowErrorMessage = true) {
        return (response: BaseResponse | any) => {
            this.busySubject.next(false);
            if (response.success) {
                if (isShowSuccessMessage) {
                    this.toastService.success(response.messages.informs.join('\n'));
                }
                return of(response.data);
            }
            if (!response.success && !isShowErrorMessage) {
                return of({});
            }
            return throwError(response);
        };
    }

    protected handleError<T>(operation: string, isShowErrorMessage = true) {
        return (response: BaseResponse | HttpErrorResponse): Observable<T> => {
            let errors = (response as BaseResponse).messages.errors as any;
            if (response instanceof HttpErrorResponse) {
                if (response.status === 500) {
                    const messageError = 'The action failed. Please contact administrator to know more issue.';
                    this.toastService.error(messageError);
                    return throwError(messageError);
                }

                errors = response.error && response.error.messages && response.error.messages.errors || [response.message];
            }
            const message = errors.join('\n');

            if (isShowErrorMessage) {
                this.toastService.error(message);
            }

            console.error(`${operation} failed: ${message}`);

            // Let the app keep running by returning an empty result.
            return throwError(message);
        };
    }
}
