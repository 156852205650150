import { Component, Input, OnInit } from '@angular/core';
import { HoleInfo, Leaderboard, TeamPlayer } from 'ec-leaderboard/dist/leaderboard';

@Component({
    templateUrl: './leaderboard-modal.component.html',
    styleUrls: ['./leaderboard-modal.component.scss'],
})
export class LeaderboardModalComponent implements OnInit {
    @Input()
    modalData: {
        leaderboard: Leaderboard,
        currentPlayer: TeamPlayer,
        currentHole: number
    };

    @Input()
    holesInfo: HoleInfo[];

    players: TeamPlayer[];

    ngOnInit() {
        if (this.modalData.leaderboard.teams) {
            const team = this.modalData.leaderboard.teams.find((t) => t.team === this.modalData.currentPlayer.first_name);
            this.players = team ? team.hole_scores[this.modalData.currentHole].players : [];
        } else {
            this.players = this.modalData.leaderboard.players.filter((item) => item.team === this.modalData.currentPlayer.team);
        }
    }
}
