import {EC_BANNER_URL, EC_HOLES_DATA, EC_START_HOLE, EC_TEAM_DATA, EC_TOKEN, EC_TOURNAMENT_DATA} from '../constants';
import { HoleInfo, Team, Tournament } from 'ec-leaderboard/dist/leaderboard';

export class InfoService {
    private tokensMap = new Map();
    private readonly ecKeys = [EC_TOKEN, EC_HOLES_DATA, EC_TOURNAMENT_DATA, EC_TEAM_DATA, EC_BANNER_URL, EC_START_HOLE];

    areTokensEmpty() {
        return !localStorage.getItem(EC_TOKEN);
    }

    clearAppData() {
        this.tokensMap.clear();
        this.ecKeys.forEach((key) => localStorage.removeItem(key));
    }

    hasCode(code: string) {
        return this.tokensMap.has(code);
    }

    getTokenByCode(code: string) {
        return this.tokensMap.get(code);
    }

    storeToken(code: string, token: string) {
        this.tokensMap.set(code, token);
        localStorage.setItem(EC_TOKEN, token);
    }

    storeStartHole(startHole: string) {
        localStorage.setItem(EC_START_HOLE, startHole);
    }

    restoreStartHole(): string {
        return localStorage.getItem(EC_START_HOLE) || '1';
    }

    storeHolesData(holeInfo: HoleInfo[]) {
        localStorage.setItem(EC_HOLES_DATA, JSON.stringify(holeInfo));
    }

    restoreHolesData(): HoleInfo[] {
        try {
            return JSON.parse(localStorage.getItem(EC_HOLES_DATA));
        } catch (err) {
            console.error(err);
        }
    }

    storeTournamentData(tournament: Tournament) {
        localStorage.setItem(EC_TOURNAMENT_DATA, JSON.stringify(tournament));
    }

    restoreTournamentData(): Tournament {
        try {
            return JSON.parse(localStorage.getItem(EC_TOURNAMENT_DATA));
        } catch (err) {
            console.error(err);
        }
    }

    storeTeamData(team: Team) {
        localStorage.setItem(EC_TEAM_DATA, JSON.stringify(team));
    }

    restoreTeamData(): Team {
        try {
            return JSON.parse(localStorage.getItem(EC_TEAM_DATA));
        } catch (err) {
            console.error(err);
        }
    }

    public updateTeamName(name: string) {
        const teamData = localStorage.getItem(EC_TEAM_DATA);
        try {
            if (teamData) {
                localStorage.setItem(EC_TEAM_DATA, JSON.stringify({ ...JSON.parse(teamData), name }));
            }
        } catch (err) {
            console.error(err);
        }
    }

    storeBanner(bannerUrl: string) {
        localStorage.setItem(EC_BANNER_URL, bannerUrl);
    }

    restoreBanner(): string {
        return localStorage.getItem(EC_BANNER_URL) || '/assets/images/adSample.jpg';
    }
}
