import { Component } from '@angular/core';
import { ModalService } from '../../services';

@Component({
  selector: 'app-restore-code-modal',
  templateUrl: './restore-code-modal.component.html',
  styleUrls: ['./restore-code-modal.component.scss'],
})
export class RestoreCodeModalComponent {
  state: {
    name: string,
    email: string
  } = {
      name: '',
      email: ''
    };

  constructor(private modalService: ModalService) { }

  handleNameValue($event) {
    const name: string = $event.target.value;
    this.state = {
      ...this.state,
      name
    };
  }

  handleEmailValue($event) {
    const email: string = $event.target.value;
    this.state = {
      ...this.state,
      email
    };
  }

  dismiss($event) {
    $event.preventDefault();
    if (this.verifyData()) {
      this.modalService.close({ ...this.state });
    }
  }

  verifyData = () => {
    // tslint:disable-next-line:max-line-length
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isNameValid = this.state.name.length > 0 && this.state.name.match(/[A-Za-z]/g);
    const isEmailValid = this.state.email.length > 5 && re.test(this.state.email.toLowerCase());
    return isNameValid && isEmailValid;
  }
}
