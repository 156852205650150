import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import {
    FooterComponent, AboutModalComponent, TechSupportModalComponent, RestoreCodeModalComponent, CommonFooterComponent, MenuModalComponent
} from './components';
import { ScoreTableComponent } from './components/score-table/score-table.component';
import { ScoreTableModalComponent } from './components/score-table/score-table-modal/score-table-modal.component';
import { UpdateScoreModalComponent } from './components/score-table/update-score-modal/update-score-modal.component';
import { ToastService, ModalService, InfoService, GeneralService } from './services';
import { TournamentBaseService } from './services/tournament-base.service';
import { AuthInterceptor } from './auth/auth-interceptor';

const DECLARED_EXPORTS = [
    FooterComponent, AboutModalComponent, TechSupportModalComponent, RestoreCodeModalComponent, CommonFooterComponent, MenuModalComponent,
    ScoreTableComponent, ScoreTableModalComponent, UpdateScoreModalComponent
];

const RELAYED_EXPORTS = [
    CommonModule, HttpClientModule, FormsModule, IonicModule
];

const ENTRY_COMPONENTS = [
    AboutModalComponent, TechSupportModalComponent, RestoreCodeModalComponent, MenuModalComponent, ScoreTableModalComponent,
    UpdateScoreModalComponent
];

@NgModule({
    declarations: [
        ...DECLARED_EXPORTS
    ],
    providers: [
        InfoService,
        ToastService,
        ModalService,
        GeneralService,
        TournamentBaseService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ],
    imports: [
        RouterModule,
        ...RELAYED_EXPORTS
    ],
    exports: [
        ...RELAYED_EXPORTS,
        ...DECLARED_EXPORTS
    ],
    entryComponents: [
        ...ENTRY_COMPONENTS
    ]
})
export class BaseModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: BaseModule,
            providers: []
        };
    }
}
