import {Component, Input, OnInit} from '@angular/core';

export interface FooterLink {
    icon?: string;
    customIcon?: string;
    name: string;
    title: string;
    url: string;
}

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    @Input() wide: boolean;
    @Input() link: string;
    @Input() links: FooterLink[];

    public activeLink: FooterLink;

    ngOnInit() {
        if (!this.links) {
            this.links = [
                {
                    icon: 'trophy',
                    name: 'leaderboard',
                    title: 'leaderboard',
                    url: '/leaderboard'
                },
                {
                    customIcon: '/assets/golf-ball.svg',
                    name: 'team',
                    title: 'my team',
                    url: '/tournament'
                }
            ];
        }
        this.activeLink = this.links.find((fl) => fl.name === this.link);
    }
}
