import { Component, Input, OnInit } from '@angular/core';
import { HoleInfo } from 'ec-leaderboard/dist/leaderboard';
import {InfoService, ModalService} from '../../../base/services';

@Component({
  selector: 'app-all-holes-modal',
  templateUrl: './all-holes-modal.component.html',
  styleUrls: ['./all-holes-modal.component.scss'],
})
export class AllHolesModalComponent implements OnInit {
  @Input() currentHole: any;
  holesInfo: HoleInfo[];

  constructor(private infoService: InfoService, private modalService: ModalService) { }

  ngOnInit() {
    this.holesInfo = this.infoService.restoreHolesData();
  }

  close() {
    this.modalService.close();
  }
}
