import { Component, Input, OnInit } from '@angular/core';
import { Events } from '@ionic/angular';
import { take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HoleInfo, TeamPlayer, TeamScores } from 'ec-leaderboard/dist/leaderboard';

import { ModalService, InfoService } from '../../../base/services';
import { TournamentBaseService } from '../../../base/services/tournament-base.service';

import { UpdateScoreModalComponent } from './update-score-modal/update-score-modal.component';
import { AllHolesModalComponent } from '../../../leaderboard/components';

// internal events
const CHANGE_HOLE_EVENT = 'change-hole-event';

// component's state
interface ScoreTableState {
    isDropdownVisible: boolean;
    currentHole: number;
}

@Component({
    selector: 'app-score-table',
    templateUrl: './score-table.component.html',
    styleUrls: ['./score-table.component.scss'],
})
export class ScoreTableComponent implements OnInit {
    scores: TeamScores;
    @Input() viewEnterEvent: Observable<boolean>;
    holes: HoleInfo[] = [];

    state: ScoreTableState = {
        isDropdownVisible: false,
        currentHole: 0
    };

    constructor(
        private events: Events,
        private modalService: ModalService,
        private infoService: InfoService,
        private tournamentBaseService: TournamentBaseService) {}

    ngOnInit() {
        this.events.subscribe(CHANGE_HOLE_EVENT, (data) => this.state = {
            ...this.state,
            currentHole: data.num >= 0 ? data.num : this.state.currentHole + data.inc
        });
        if (this.viewEnterEvent) {
            this.viewEnterEvent.subscribe(() => this.viewEnter());
        }
    }

    private viewEnter() {
        this.changeHole(+this.infoService.restoreStartHole() - 1);
        this.loadTeamScores();
    }

    private loadTeamScores() {
        this.tournamentBaseService.getTeamScores()
            .pipe(
                take(1),
                tap(value => {
                    this.scores = value && value.team;
                    if (this.scores.players && this.scores.players.length > 0) {
                        this.holes = this.scores.players[0].holes;
                        this.infoService.storeHolesData(this.holes);
                    }
                })
            )
            .subscribe();
    }

    prevHoleClick() {
        const holesMaxInc = this.holes.length - 1;
        this.events.publish(CHANGE_HOLE_EVENT, { inc: this.state.currentHole > 0 ? -1 : holesMaxInc });
    }

    nextHoleClick() {
        const holesMaxInc = this.holes.length - 1;
        this.events.publish(CHANGE_HOLE_EVENT, { inc: this.state.currentHole < holesMaxInc ? 1 : -holesMaxInc });
    }

    changeHole(i: number) {
        this.events.publish(CHANGE_HOLE_EVENT, { num: i });
    }

    async updateScoreClick(player: TeamPlayer, currentHole: any) {
        const enteredScore = (currentHole.score || '') + '';
        const modal = await this.modalService.show(
            UpdateScoreModalComponent, 'update-score-modal', { player, currentHole, enteredScore }
            );
        modal.onDidDismiss().then((result: any) => {
            if (result.data && result.data.score >= 0) {
                currentHole.score = result.data.score;
                this.replaceScore(result.data.player, result.data.score);
            }
        });
    }

    async allHolesClickHandler($event) {
        const props = {
            modalData: $event,
            currentHole: this.state.currentHole
        };
        await this.modalService.show(AllHolesModalComponent, 'full-screen-modal', props);
    }

    ionModalDidDismiss() {
        alert('dismissed');
    }

    adjustScore(player: TeamPlayer, inc: number) {
        const currentHole = this.state.currentHole + 1;
        const currentScore = player.holes[currentHole - 1].score || 0;
        const newScore = currentScore + inc;
        const currentPlayer: TeamPlayer = this.tournamentBaseService.currentTeamData.members.find((p) =>
            p.first_name === player.first_name && p.last_name === player.last_name
        );
        this.tournamentBaseService.updatePlayerScores(currentHole, currentPlayer.uuid, newScore > -1 ? newScore : 0);
    }

    replaceScore(player: TeamPlayer, newScore: number) {
        const currentHole = this.state.currentHole + 1;
        const currentPlayer = this.tournamentBaseService.currentTeamData.members.find((p) =>
            p.first_name === player.first_name && p.last_name === player.last_name
        );
        this.tournamentBaseService.updatePlayerScores(currentHole, currentPlayer.uuid, newScore > -1 ? newScore : 0);
    }

    sum(player: TeamPlayer) {
        return player.holes.reduce((ac: number, val) => ac + val.score, 0);
    }
}
