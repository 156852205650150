import { Injectable, } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Injectable()
export class ModalService {
    modal: HTMLIonModalElement;

    constructor(private modalController: ModalController) { }

    async show(component: any, cssClass: string, componentProps: any) {
        return await this.presentModal(component, cssClass, componentProps);
    }

    close(state: any = null) {
        this.modal.dismiss(state);
    }

    private async presentModal(component: any, cssClass: string, componentProps: any) {
        this.modal = await this.modalController.create({
            component,
            cssClass,
            componentProps
        });
        await this.modal.present();
        return this.modal;
    }
}
