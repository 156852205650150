// events constants
export const CLOSE_DD_EVENT = 'close-dd-event';

// local storage constants
export const EC_TOKEN = 'ec_token';
export const EC_TEAM_DATA = 'ec_team_data';
export const EC_TOURNAMENT_DATA = 'ec_tournament_data';
export const EC_HOLES_DATA = 'ec_holes_data';
export const EC_BANNER_URL = 'ec_banner_url';
export const EC_START_HOLE = 'ec_start_hole';

// API root path
export const API_BASE_URL = 'https://app.eventcaddy.com/api/v1';

// Leaderboard polling interval
export const LEADERBOARD_POLL_INT = 30 * 1000;

// Default HTTP timeout
export const REQ_TIMEOUT = 60 * 1000;
