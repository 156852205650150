import { Injectable, Inject, forwardRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Tournament } from 'ec-leaderboard/dist/leaderboard/lib/types';

import { BaseService } from '../../base/services/base.service';
import { ToastService } from '../../base/services/toast.service';
import { InfoService } from '../../base/services/info.service';
import { BannerResponse } from '../../base/models/base-interface';

import { GeneralApi } from '../api';

@Injectable()
export class GeneralService extends BaseService {

    constructor(
        @Inject(forwardRef(() => HttpClient))
        http: HttpClient,
        toastService: ToastService,
        private infoService: InfoService
    ) {
        super(http, toastService);
    }

    get currentTournamentData() {
        return this.infoService.restoreTournamentData();
    }

    getBanner() {
        return this.get<BannerResponse>(GeneralApi.getBannerUrl(this.currentTournamentData.code), false);
    }
}
