import { Component, Input } from '@angular/core';
import { ModalService } from '../../../services';

@Component({
  selector: 'app-about-modal',
  templateUrl: './about-modal.component.html',
  styleUrls: ['./about-modal.component.scss'],
})
export class AboutModalComponent {
  @Input() modalData: any;

  constructor(private modalService: ModalService) { }

  closeModal() {
    this.modalService.close();
  }
}
